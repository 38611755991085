import * as React from 'react';
import { graphql, Link } from 'gatsby';
//components
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { colors, device } from '../components/GlobalStyles';
import { DoctorsSlide } from '../components/DoctorsSlide';
import { Seo } from '../components/Seo';

import { Testimonials } from '../components/Testimonials';
//layout
import { DefaultLayout } from '../layouts/DefaultLayout';

const Section = styled.section`
    margin-top: 8em;
    padding: 0em 1em;
    .breadcrumbs {
        display: none;
    }
    span {
        font-size: 21px;
        font-weight: bold;
    }
    h4 {
        margin-bottom: 2em;
    }
    h1 {
        margin: 1em 0em 0.2em;
        font-size: 59px;
        line-height: 64px;
    }
    h2 {
        margin: 0.5em 0em;
    }
    p {
        margin-top: 1em;
    }
    p:nth-of-type(8) {
        margin-bottom: 4em;
    }
    h6 {
        font-size: 35px;
        margin-top: 1.5em;
    }
    @media ${device.tablet} {
        .pic {
            max-width: 300px;
        }
        .breadcrumbs {
            font-size: 13px;
            display: flex;
            display: flex;
            width: 380px;
            margin: 0 auto;
            justify-content: space-around;
            li {
                color: grey;
                cursor: pointer;
                &:hover {
                    color: black;
                }
            }
        }
        padding: 0em 8em;
        h2 {
            margin-bottom: 1em;
            text-align: center;
        }
    }
    @media ${device.laptopL} {
        .content,
        .top-text {
            max-width: 1440px;
            margin: 24px auto;
        }
    }
`;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AboutUs: Page<any> = ({ data }) => {
    const { mdx } = data;
    const { meta } = mdx.frontmatter;
    return (
        <>
            <Seo {...meta} />
            <Section>
                <ul className="breadcrumbs">
                    <Link to="/">
                        <li style={{ listStyleType: 'none' }}>HOME</li>
                    </Link>
                    <Link to="/about-us/">
                        <li>ABOUT US</li>
                    </Link>
                    <li style={{ color: 'black' }}>{mdx.frontmatter.name}</li>
                </ul>
                <div className="top-text">
                    <div className="flex-container">
                        <div>
                            <h2>{`${mdx.frontmatter.name}`}</h2>
                            <div className="image">
                                <h4>ABOUT US</h4>
                                <GatsbyImage
                                    className="pic"
                                    alt="frontal picture of doctor"
                                    image={
                                        mdx.frontmatter.featuredImage.childImageSharp
                                            .gatsbyImageData
                                    }
                                />
                            </div>
                        </div>
                        <div className="text-container">
                            <div className="content">
                                <h1>{mdx.frontmatter.title}</h1>
                                {mdx.frontmatter.title === 'Meet Dr. M. Reza Iranmanesh' && (
                                    <span>DMD, MSD, PA</span>
                                )}
                                {mdx.frontmatter.title === 'Meet Nika Iranmanesh' && (
                                    <>
                                        <span>DMD</span>
                                        <span
                                            style={{ marginLeft: '.5em', color: `${colors.green}` }}
                                        >
                                            PROSTHODONTIST
                                        </span>
                                    </>
                                )}

                                {mdx.frontmatter.title === 'Meet Nima Iranmanesh' && (
                                    <>
                                        <span>DMD</span>
                                        <span
                                            style={{ marginLeft: '.5em', color: `${colors.green}` }}
                                        >
                                            PROSTHODONTIST
                                        </span>
                                    </>
                                )}
                                {mdx.frontmatter.title === 'Meet Armita Mashkouri' && (
                                    <>
                                        <span>DMD, M.S.</span>
                                        <span
                                            style={{ marginLeft: '.5em', color: `${colors.green}` }}
                                        >
                                            PERIODONTIST
                                        </span>
                                    </>
                                )}

                                <MDXRenderer>{mdx.body}</MDXRenderer>
                            </div>
                            {mdx.frontmatter.slug === 'dr-m-reza-iranmanesh' && (
                                <>
                                    <GatsbyImage
                                        alt="lifetime award celebration with a group"
                                        image={
                                            data.award.edges[0].node.childImageSharp.gatsbyImageData
                                        }
                                    />
                                    <h6>Lifetime Achievment Award</h6>
                                    <p>
                                        In 2016, Dr. Iranmanesh received the “Life Achievement
                                        Award” from the Hillsborough County Dental Association
                                        (HCDA). The Award recognizes the Doctor’s outstanding
                                        Leadership, Dedication, and Service in the field of
                                        professional dentistry.
                                    </p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Section>
            <DoctorsSlide images={data.doctors.edges} />
            <Testimonials />
        </>
    );
};

export const query = graphql`
    query ($id: String!) {
        mdx(id: { eq: $id }) {
            frontmatter {
                title
                slug
                name
                categories
                meta {
                    title
                    description
                }
                featuredImage {
                    name
                    childImageSharp {
                        gatsbyImageData(
                            width: 400
                            placeholder: BLURRED
                            quality: 90
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
            body
            id
        }
        allMdx(filter: { fields: { sourceInstanceName: { eq: "doctors" } } }) {
            nodes {
                frontmatter {
                    slug
                    title
                    categories
                }
                body
                excerpt
                slug
                id
            }
        }
        award: allFile(filter: { relativePath: { regex: "/award.jpeg/" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(
                            width: 400
                            placeholder: BLURRED
                            quality: 90
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
            }
        }
        doctors: allFile(filter: { relativeDirectory: { eq: "doctorCards" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(quality: 90, formats: [AUTO, WEBP, JPG])
                    }
                    name
                }
            }
        }
    }
`;

export default AboutUs;

AboutUs.Layout = DefaultLayout;
